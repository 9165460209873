/*
 * Ryan O'Dowd
 * 2019-01-08
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Card,
  CardContent,
} from '@mui/material';
import styles from './styles';
import {
  useNavigate,
} from 'react-router-dom';

const HomeCard = ({description, href, icon, name, isAlphaFeature = null}) => {
  const navigate = useNavigate();

  return (
    <Card
      style={{...styles.container, ...(isAlphaFeature ? styles.alphaFeature : {})}}
      onClick={() => {
        if (href.startsWith('https://')) {
          window.location = href;
        } else {
          navigate(href.split('/:')[0]);
        }
      }}
    >
      <CardContent style={styles.cardContent}>
        <h3 style={styles.name}>{icon}&nbsp;&nbsp;{name}</h3>
        <p style={styles.description}>{description}</p>
      </CardContent>
    </Card>
  );
};

export default HomeCard;
