/*
 * Nathaniel Padgett
 * 2021-07-28
 * © Copyright 2021 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import Globals, {
  BETA_ENV,
  PROD_ENV,
} from '../../../Globals';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import CloseIcon from 'mdi-react/CloseIcon';
import React from 'react';
import {
  decodeJwt,
} from '../../../utilities';
import {
  fetchCurrentAdmin,
} from '../../../actions';
import styles from './styles';

const Header = () => {
  const [_anchorEl, setAnchorEl] = React.useState(null);
  const admin = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const isMasquerading = localStorage.getItem('jwt') && admin.username !== decodeJwt(localStorage.getItem('jwt')).username;

  return ((Globals.env !== PROD_ENV && Globals.env !== BETA_ENV) &&
    <>
      {/* @TODO: add hide button to this indicator */}
      {isMasquerading
        ? (
          <div style={styles.devIndicator}>
            {`Masquerading as ${admin.first_name} ${admin.last_name}`}
            <Button
              aria-owns={_anchorEl ? 'perms-menu' : undefined}
              aria-haspopup='true'
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              Perms
            </Button>
            <Menu
              id='perms-menu'
              anchorEl={_anchorEl}
              open={!!_anchorEl}
              onClose={() => setAnchorEl(null)}
            >
              {Object.keys(admin.perms).map((perm) => {
                let value = admin.perms[perm];
                if (!Number.isInteger(+value)) {
                  const validPerms = Object.keys(admin.perms[perm]).filter((subPerm) => {
                    return +admin.perms[perm][subPerm] === 1;
                  });

                  if (validPerms.length === 0) {
                    value = 'none';
                  } else if (validPerms.length === admin.perms[perm].length) {
                    value = 'all';
                  } else {
                    value = validPerms.join(', ');
                  }
                }

                return <MenuItem key={perm} disabled={true}>{`${perm}: ${value}`}</MenuItem>;
              })}
            </Menu>
            <IconButton
              style={styles.stopMasqueradingButton}
              color='inherit'
              onClick={() => dispatch(fetchCurrentAdmin())}
              size='large'
            >
              <CloseIcon />
            </IconButton>
          </div>
        ) : (
          <div style={styles.devIndicator}>DEV ENV: changes here will not affect production data</div>
        )}
      <div style={styles.devSpacer} />
    </>
  );
};

export default Header;
