/*
 * Nathaniel Padgett
 * 2021-08-25
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Autocomplete,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';

const KnownSchoolsTextField = (props) => {
  return (
    <Autocomplete
      renderInput={(params) => <TextField {...params} label={props.inputLabel}></TextField>}
      autoComplete={true}
      disableClearable={true}
      options={Object.values(props.institutionCampuses) || []}
      noOptionsText={props.noOptionsText || ''}
      // @TODO use id as value instead of formatted school name
      getOptionLabel={(option) => `${option.name} - ${option.city}, ${option.state}`}
      onChange={(e, value) => {
        props.onChange(e, value);
      }}
    />
  );
};

KnownSchoolsTextField.propTypes = {
  institutionCampuses: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,

  inputLabel: PropTypes.string,
  noOptionsText: PropTypes.string,
};

export default KnownSchoolsTextField;
