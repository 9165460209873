/*
 * Ryan O'Dowd
 * 2019-08-07
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    width: '100%',
    textAlign: 'left',
    color: Globals.colors.light,
  },
  tableToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  highlightedText: {
    color: Globals.colors.light,
    backgroundColor: Globals.colors.accent,
  },
  textField: {
    marginRight: 8,
    // @TODO: change placeholder text color
  },
  countsText: {
    color: Globals.colors.dark,
  },
  reportsContainer: {
    display: 'flex',
    padding: 10,
    marginTop: 20,
    flexWrap: 'wrap',
  },
  datePickerRow: {
    display: 'flex',
  },
  cardWrapper: {
    padding: 10,
    flexGrow: 1,
  },
  formRow: {
    padding: 5,
  },
  spacer: {
    width: 10,
  },
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  tableWrapper: {
    overflow: 'auto',
  },
  registrationCardWrapper: {
    padding: 10,
    flexGrow: 2,
  },
};

export default styles;
