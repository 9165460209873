/*
 * Nick Lewyn
 * 2025-01-08
 * © Copyright 2025 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Autocomplete,
  TextField,
} from '@mui/material';
import styles from '../styles';

const FacultySearchAutoComplete = ({
  instructors = null,
  selectCallBack = null,
  sort = true,
}) => {
  return (
    <div style={styles.selectContainer}>
      <Autocomplete
        style={{width: 250}}
        id='instructorSelect'
        options={sort ? instructors.sort((i1, i2) => {
          return (i1.first_name > i2.first_name) ? 1 : (i1.first_name < i2.first_name) ? -1 : 0;
        }) : instructors}
        getOptionLabel={(instructor) => `${instructor.first_name} ${instructor.last_name}`}
        onChange={(e, newValue, reason) => {
          switch (reason) {
            case 'selectOption':
              selectCallBack(newValue);
              break;
            case 'clear':
              selectCallBack(null);
              break;
            default:
              break;
          }
        }}
        renderOption={(props, instructor) => (
          <li {...props}>
            {instructor.first_name} {instructor.last_name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Choose a faculty member'
          />
        )}
      />
    </div>

  );
};

export default FacultySearchAutoComplete;
