/*
 * Ally Zernick
 * 2022-06-16
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Paper,
  Radio,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import {
  addStudentToStudentProgress,
  exportStudentProgressReport,
  fetchCourses,
  fetchPartnerAgreements,
  fetchStudent,
  fetchStudentProgressData,
  fetchStudents,
  getAllCampuses,
  getAllCampusesWithProgressReportStudents,
  getTerms,
  patchStudentProgressData,
  patchStudentProgressVisibility,
  removeStudentProgressData,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useEffect,
  useState,
} from 'react';
import {
  AdapterDayjs,
} from '@mui/x-date-pickers/AdapterDayjs';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import FlagIcon from 'mdi-react/FlagIcon';
import Globals from '../../Globals';
import HasViolation from '../HasViolation';
import Highlighter from 'react-highlight-words';
import KnownSchoolsTextField from '../SchoolNames/KnownSchoolsTextField';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import styles from './styles';

dayjs.extend(localizedFormat);

const StudentProgress = () => {
  const [_openDialog, setOpenDialog] = useState(false);
  const [_campusId, setCampusId] = useState('');
  const [_progressReportSchool, setProgressReportSchool] = useState(null);
  const [_selectedTerm, setSelectedTerm] = useState('');
  const [_selectedPartnerAgreement, setSelectedPartnerAgreement] = useState();
  const [_selectedCourses, setSelectedCourses] = useState([]);
  const [_schoolDeadlineDate, setSchoolDeadlineDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [_studentId, setStudentId] = useState(null);
  const [_searchTerm, setSearchTerm] = useState('');
  const [_edit, setEdit] = useState(false);
  const [_editRow, setEditRow] = useState(0);
  const [_studentProgressReportDisabled, setStudentProgressReportDisabled] = useState(true);
  const [_rowsPerPage, setRowsPerPage] = useState(5);
  const [_pageNum, setPageNum] = useState(0);
  const [_displayActiveStudents, setDisplayActiveStudents] = useState(true);
  const _students = useSelector((state) => Object.values(state.students));
  const _displayData = useSelector((state) => state.fetchStudentProgressData);
  const _courses = useSelector((state) => state.courses);
  const _activeCampusesWithProgressReportStudents = useSelector((state) => state.activeCampusesWithProgressReportStudents);
  const _allCampuses = useSelector((state) => state.allCampuses);
  const _terms = useSelector((state) => state.terms);
  const _partnerAgreements = useSelector((state) => state.partnerAgreements);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCourses());
    dispatch(getTerms());
    dispatch(getAllCampuses());
    dispatch(getAllCampusesWithProgressReportStudents());
    dispatch(fetchPartnerAgreements());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchStudents(_displayActiveStudents, '', null));
  }, [_displayActiveStudents, dispatch]);

  useEffect(() => {
    if (_studentId !== null && _selectedCourses.length && _schoolDeadlineDate !== null && _selectedTerm !== '' && _campusId !== '') {
      setStudentProgressReportDisabled(false);
    }
  }, [_studentId, _campusId, _schoolDeadlineDate, _selectedTerm, _selectedCourses]);

  const _resetState = () => {
    setOpenDialog(false);
    setSelectedCourses([]);
    setStudentProgressReportDisabled(true);
    setSelectedTerm('');
    setCampusId('');
    setStudentId(null);
  };

  const _handleCourseCheckboxChange = (event, course) => {
    if (event.target.checked) {
      setSelectedCourses([..._selectedCourses, course]);
    } else {
      setSelectedCourses(_selectedCourses.filter((c) => c !== course));
    }
  };

  const _addEnrollment = () =>
    <Button
      disabled={_studentProgressReportDisabled}
      onClick={() => {
        setProgressReportSchool(_activeCampusesWithProgressReportStudents.find((school) => school.id === _campusId));
        dispatch(addStudentToStudentProgress(_studentId, _selectedCourses, _campusId, _selectedTerm, _schoolDeadlineDate, () => dispatch(fetchStudentProgressData(_campusId))));
        _resetState();
      }}
      style={styles.buttonStyle}
      variant='contained'
      color='primary'
    >
      Add
    </Button>;

  const _viewSchoolsExport = () =>
    <FormControl style={styles.formControlSelectSchool} variant='outlined'>
      <Autocomplete
        style={styles.selectOptions}
        id='combo-box-demo'
        value={_progressReportSchool}
        disableClearable={true}
        options={_activeCampusesWithProgressReportStudents}
        getOptionLabel={(school) => school.name}
        onChange={(e, school) => {
          setProgressReportSchool(school);
          dispatch(fetchStudentProgressData(school.id));
        }}
        renderInput={(params) => <TextField {...params} label='Select a school' variant='outlined' />}
      />
    </FormControl>;

  const _addStudentToSchoolExport = () => {
    let filteredStudents;
    let wordsToMatch;
    let emptyRows;

    if (_students.length > 0) {
      const searchTerms = _searchTerm.split('|').filter((multipleSearchTerms) => multipleSearchTerms).map((searchTermPart) => searchTermPart.split(' ').map((text) => text.toLowerCase()));

      wordsToMatch = () => {
        let searchWords = [];
        _searchTerm.split(' ').forEach((term) => {
          if (term.includes('|')) {
            searchWords = [...searchWords, ...term.split('|').filter((value) => value)];
          } else {
            searchWords = [...searchWords, term];
          }
        });
        return searchWords;
      };

      filteredStudents = _students.filter((student) => {
        if (!_searchTerm) {
          return true;
        }

        return searchTerms.some((groupOfSearchTerms) => {
          return groupOfSearchTerms.every((searchTerm) => {
            return Object.keys(student).some((studentAttribute) => {
              if (studentAttribute === 'phone') {
                return `${student[studentAttribute]}`.replace(/\D/g, '').includes(searchTerm);
              }
              return `${student[studentAttribute]}`.toLowerCase().includes(searchTerm);
            });
          });
        });
      });

      emptyRows = _rowsPerPage - Math.min(_rowsPerPage, filteredStudents.length - _pageNum * _rowsPerPage);
    }
    return (
      <div style={styles.addStudentToExportDiv}>
        <h2>Click the button below to add a new student to a progress report</h2>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {setOpenDialog(true);}}
        >
          ADD STUDENT
        </Button>
        <Dialog maxWidth='lg' open={_openDialog} onClose={_resetState}>
          <DialogTitle>{`Add Student to a School's Progress Report`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please select the student you want to add to a progress report then choose the courses, school, semester and year to add.
            </DialogContentText>
            <div style={styles.studentIdDiv}>
              <div style={styles.detailsRow}>
                <h3 style={styles.idEntry}>Search Student: </h3>
                <TextField
                  id='studentSearchLabel'
                  style={styles.textField}
                  value={_searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  label='Search'
                  margin='dense'
                  variant='outlined'
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={_displayActiveStudents}
                      onChange={(event) => {
                        setDisplayActiveStudents(event.target.checked);
                      }}
                    />
                  }
                  label='Active only'
                />
              </div>
              {_students.length > 0 &&
                <Paper style={styles.smallPaper}>
                  <Table
                    id='studentSearch'
                    aria-labelledby='tableTitle'
                    size='medium'
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align='left'>Student Id</TableCell>
                        <TableCell align='left'>Name</TableCell>
                        <TableCell align='left'>Email</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredStudents.slice(_pageNum * _rowsPerPage, _pageNum * _rowsPerPage + _rowsPerPage).map((student) => {
                        return (
                          <TableRow
                            style={+_studentId === +student.id ? styles.selectedStudentRow : styles.studentRow}
                            hover
                            tabIndex={-1}
                            onClick={() => {
                              dispatch(fetchStudent(student.id));
                              setStudentId(student.id);
                            }}
                            key={student.id}
                          >
                            <TableCell align='left'>
                              <Radio
                                checked={_studentId === student.id}
                                name='studentSelect'
                                value={student.id}
                                onChange={() => {
                                  setStudentId(student.id);
                                }}
                              />
                              <Highlighter
                                highlightStyle={styles.highlightedText}
                                searchWords={wordsToMatch()}
                                autoEscape={true}
                                textToHighlight={`${student.id}`}
                              />
                            </TableCell>
                            <TableCell align='left'>
                              <Highlighter
                                highlightStyle={styles.highlightedText}
                                searchWords={wordsToMatch()}
                                autoEscape={true}
                                textToHighlight={`${student.first_name} ${student.last_name}`}
                              />
                            </TableCell>
                            <TableCell align='left'>
                              <Highlighter
                                highlightStyle={styles.highlightedText}
                                searchWords={wordsToMatch()}
                                autoEscape={true}
                                textToHighlight={student.email}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{height: 75 * emptyRows}}>{/* @TODO: magic number */}
                          <TableCell colSpan={4} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component='div'
                    count={Object.keys(filteredStudents).length}
                    rowsPerPage={_rowsPerPage}
                    page={_pageNum}
                    previousButton={{'aria-label': 'Previous Page'}}
                    nextButton={{'aria-label': 'Next Page'}}
                    onPageChange={(event, _pageNum) => {setPageNum(_pageNum);}}
                    onRowsPerPageChange={(e) => {setRowsPerPage(e.target.value);}}
                  />
                </Paper>
              }
              <div>
                <FormControl style={styles.checkboxes} component='fieldset'>
                  <FormLabel component='legend'>Available Courses</FormLabel>
                  {_renderCourseList()}
                </FormControl>
              </div>
            </div>
            <div style={styles.dropDowns}>
              <h3>Select School  |  Term  |  Deadline</h3>
              <FormControl style={styles.formControlSelectSchool} variant='outlined'>
                {Object.keys(_allCampuses).length > 0 &&
                  <KnownSchoolsTextField
                    onChange={(e, campus) => {
                      setCampusId(campus.id);
                      setSelectedPartnerAgreement(_partnerAgreements.find((p) => p.institution_campus_id === campus.id)?.partner_agreement_id);
                    }}
                    noOptionsText='No Institution Campus'
                    inputLabel='School'
                    institutionCampuses={_allCampuses}
                  />
                }
              </FormControl>
              <FormControl style={styles.formControlSelectSecondary} variant='outlined'>
                {Object.keys(_terms).length > 0 &&
                  <Autocomplete
                    style={styles.selectOptions}
                    disableClearable={true}
                    options={_terms.filter((t) => t.institution_campus_partner_agreement_id === null || t.institution_campus_partner_agreement_id === _selectedPartnerAgreement).map((t) => ({
                      id: t.id,
                      term_name: t.term_name,
                      partner_id: t.institution_campus_partner_agreement_id,
                    }))}
                    getOptionLabel={(term) => term.term_name}
                    onChange={(e, term) => setSelectedTerm(term.id)}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        label='Term'
                        variant='outlined'
                      />}
                  >
                  </Autocomplete>
                }
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label='School Deadline Date'
                  style={styles.datePicker}
                  value={dayjs(_schoolDeadlineDate)}
                  onChange={(e, value) => setSchoolDeadlineDate(e.format('YYYY-MM-DD'))}
                />
              </LocalizationProvider>
              <br />
              {_addEnrollment()}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => _resetState()}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const _saveEdits = () => {
    const studentData = _displayData[_editRow];
    if (studentData.id !== null) {
      dispatch(patchStudentProgressData(studentData.id, _selectedTerm, _schoolDeadlineDate,
        () => dispatch(fetchStudentProgressData(_progressReportSchool.id))));
    } else {
      dispatch(addStudentToStudentProgress(studentData.student_id, [studentData.course_id],
        _progressReportSchool.id, _selectedTerm, _schoolDeadlineDate,
        () => dispatch(fetchStudentProgressData(_progressReportSchool.id))));
    }
    setEdit(false);
  };

  const _cancelEdits = () => {
    setEdit(false);
  };

  const _setEdit = (index, term, deadline) => {
    setEditRow(index);
    setSchoolDeadlineDate(deadline);
    setEdit(true);

    Object.values(_terms).forEach((t) => {
      if (term === t.term_name) {
        setSelectedTerm(t.id);
      }
    });
  };

  const _displayCurrentData = () =>
    <div style={styles.container}>
      <h3>Current Data:</h3>
      <Paper>
        <Table
          id='studentProgress'
          aria-labelledby='tableTitle'
          size='medium'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Student</TableCell>
              <TableCell align='left'>Student Id</TableCell>
              <TableCell align='left'>Enrollment Id</TableCell>
              <TableCell align='left'>Course</TableCell>
              <TableCell align='left'>Current Grade</TableCell>
              <TableCell align='left'>Final Letter Grade</TableCell>
              <TableCell align='left'>Term</TableCell>
              <TableCell align='left'>School Deadline</TableCell>
              <TableCell align='left'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_displayData.sort((a, b) => a.student.localeCompare(b.student)).map((studentData, index) =>
              <TableRow key={index} hover style={studentData.invisible ? styles.invisible : null}>
                <>
                  <TableCell align='left'>
                    <HasViolation severity={studentData.violation_severities} />
                    {studentData.partner_student && <Tooltip title='Partner Agreement Student'><FlagIcon style={{color: Globals.colors.primary}} /></Tooltip>}
                    {studentData.student}
                  </TableCell>
                  <TableCell align='left'> {studentData.student_id} </TableCell>
                  <TableCell align='left'> {studentData.enrollment_id} </TableCell>
                  <TableCell align='left'> {studentData.course} </TableCell>
                  <TableCell align='left'> {studentData.current_grade} </TableCell>
                  <TableCell align='left'> {studentData.final_grade} </TableCell>
                  {_edit && (index === _editRow)
                    ? <>
                      <TableCell align='left'>
                        <FormControl style={styles.formControlSelectSecondary} variant='outlined'>
                          <Autocomplete
                            style={styles.autocompleteEditTerm}
                            disableClearable={true}
                            options={_terms.map((t) => ({id: t.id, term_name: t.term_name}))}
                            getOptionLabel={(term) => term.term_name}
                            onChange={(e, term) => {
                              setSelectedTerm(term.id);
                            }}
                            renderInput={(params) =>
                              <TextField
                                {...params}
                                label='Term'
                                variant='outlined'
                              />}
                          >
                          </Autocomplete>
                        </FormControl>
                      </TableCell>
                      <TableCell align='left'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label='School Deadline'
                            disableClearable={true}
                            value={dayjs(_schoolDeadlineDate)}
                            onChange={(e) => setSchoolDeadlineDate(e.format('YYYY-MM-DD'))}
                          />
                        </LocalizationProvider>
                      </TableCell>
                      <TableCell align='left'>
                        {studentData.id &&
                          <Button
                            align='right'
                            onClick={() => {
                              dispatch(removeStudentProgressData(studentData.id, () => dispatch(fetchStudentProgressData(_progressReportSchool.id))));
                              setEdit(false);
                            }}
                          >
                            <DeleteIcon />
                          </Button>
                        }
                        <Button align='right' onClick={_saveEdits}>
                          <DoneIcon />
                        </Button>
                        <Button align='right' onClick={_cancelEdits}>
                          <CloseIcon />
                        </Button>
                      </TableCell>
                    </>
                    : <>
                      <TableCell align='left'> {studentData.term} </TableCell>
                      <TableCell align='left'> {studentData.school_deadline_date} </TableCell>
                      <TableCell align='left'>
                        <Button align='right' onClick={(() => {_setEdit(index, studentData.term, studentData.school_deadline_date);})}>
                          <CreateIcon />
                        </Button>
                        {studentData.enrollment_id !== 'Not Yet Enrolled' &&
                          <Button
                            align='left'
                            onClick={() => {
                              const invisible = studentData.invisible ? 0 : 1;
                              dispatch(patchStudentProgressVisibility(studentData.enrollment_id, _progressReportSchool.id, invisible,
                                () => dispatch(fetchStudentProgressData(_progressReportSchool.id))));
                            }}
                          >
                            {studentData.invisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </Button>
                        }
                      </TableCell>
                    </>
                  }
                </>
              </TableRow>,
            )}
          </TableBody>
        </Table>
      </Paper>
      <br />
      <Button
        onClick={() => dispatch(exportStudentProgressReport(_progressReportSchool.id))}
        style={styles.exportButton}
        variant='contained'
        color='primary'
      >
        Download Student Progress Report
      </Button>
    </div>;

  const _renderCourseList = () =>
    Object.keys(_courses).length > 0 && Object.keys(_courses)
      .sort((a, b) => _courses[a].code > _courses[b].code ? 1 : -1)
      .map((courses) =>
        <FormGroup key={courses}>
          <FormControlLabel
            control={<Checkbox name={_courses[courses].code} onChange={((e) => {_handleCourseCheckboxChange(e, _courses[courses].id);})} />}
            label={`(${_courses[courses].code}) ${_courses[courses].title}`}
          />
        </FormGroup>,
      );

  return (Object.values(_allCampuses).length === 0 ||
    Object.values(_activeCampusesWithProgressReportStudents).length === 0)
    ? <p>Loading...</p>
    : <div style={styles.container}>
      <h1>Export Student Progress </h1>
      {_addStudentToSchoolExport()}
      <div style={styles.lineSeparator}></div>
      {_viewSchoolsExport()}
      <div style={styles.lineSeparator}></div>
      {_progressReportSchool && _displayCurrentData()}
    </div>;
};

export default (StudentProgress);
