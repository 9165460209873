/*
 * Ally Zernick
 * 2023-10-20
 * © Copyright 2023 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import {
  fetchAssignmentViolationReport,
  fetchCourses,
  fetchInstructors,
  fetchIntegrityViolationReport,
  fetchViolationTypes,
  fetchViolations,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useEffect,
  useState,
} from 'react';
import {
  ResponsiveBar,
} from '@nivo/bar';
import dayjs from 'dayjs';
import styles from './styles';

const IntegrityViolations = () => {
  const [_violationType, setViolationType] = useState('all');
  const [_course, setCourse] = useState([]);
  const [_violationAssignmentCourse, setViolationAssignmentCourse] = useState(23);
  const [_rowsPerPage, setRowsPerPage] = useState(5);
  const [_pageNum, setPageNum] = useState(0);
  const [_searchTerm, setSearchTerm] = useState('');
  const [_instructor, setInstructor] = useState('all');
  const [_rescinded, setRescinded] = useState(false);
  const [_reportDisplay, setReportDisplay] = useState('Course');
  const violations = useSelector((state) => Object.values(state.integrityViolations).sort((a, b) => b.id - a.id));
  const violationTypes = useSelector((state) => state.integrityViolationTypes);
  const violationsPerAssignment = useSelector((state) => state.assignmentViolationReport);
  const loadingStates = useSelector((state) => state.loadingStates);
  const courses = useSelector((state) => state.courses);
  const instructors = useSelector((state) => Object.values(state.instructors).filter((instructor) => instructor.disabled === 0));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCourses());
    dispatch(fetchInstructors());
    dispatch(fetchViolations());
    dispatch(fetchViolationTypes());
    dispatch(fetchAssignmentViolationReport());
  }, [dispatch]);

  const _renderAssignmentStatisticsTable = () => {
    const barData = violationsPerAssignment.filter((assignment) => assignment.course_id === _violationAssignmentCourse);
    const filteredData = [];

    barData.forEach((assignment) => {
      const existingEntry = Object.keys(filteredData).find((key) => filteredData[key].name.replace('- Requires Respondus LockDown Browser', '').replace('+ Webcam', '') === assignment.assignment_name.replace('- Requires Respondus LockDown Browser', '').replace('+ Webcam', ''));
      if (filteredData[existingEntry] === undefined) {
        filteredData.push({
          name: assignment.assignment_name.replace('- Requires Respondus LockDown Browser', '').replace('+ Webcam', ''),
          [assignment.integrity_violation_type]: assignment.integrity_violation_type_count,
        });
      } else {
        Object.assign(filteredData[existingEntry], {[assignment.integrity_violation_type]: assignment.integrity_violation_type_count});
      }
    });

    filteredData.sort((a, b) => a.name.localeCompare(b.name));

    return (
      <div>
        {filteredData.length > 0 ? (
          <div>
            <div style={{height: 600, width: '100%'}}>
              <ResponsiveBar
                fit={true}
                enableTotals={true}
                data={filteredData}
                keys={['TAI', 'TAIW', 'AVW', 'AV-T1', 'AV-T2 (on camera)', 'AV-T2 (dishonesty)', 'AV-T3']}
                indexBy='name'
                margin={{
                  top: 50,
                  right: 130,
                  bottom: 75,
                  left: 60,
                }}
                borderWidth={2}
                borderColor={{
                  from: 'color',
                  modifiers: [[
                    'darker',
                    1.6,
                  ]],
                }}
                animate={false}
                padding={0.25}
                labelSkipHeight={15}
                valueScale={{type: 'linear'}}
                indexScale={{type: 'band', round: true}}
                colors={{scheme: 'purple_red'}}
                height={600}
                width={1366}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 20,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                }}
                legends={[{
                  dataFrom: 'keys',
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [{
                    on: 'hover',
                    style: {
                      itemOpacity: 1,
                    },
                  }],
                }]}
              />
            </div>
            <Table style={{border: '1px solid lightgray'}}>
              <TableHead>
                <TableRow>
                  <TableCell>Assignment</TableCell>
                  <TableCell align='center'>TAIW</TableCell>
                  <TableCell align='center'>TAI</TableCell>
                  <TableCell align='center'>AV-T1</TableCell>
                  <TableCell align='center'>AV-T2 (on camera)</TableCell>
                  <TableCell align='center'>AV-T2 (dishonesty)</TableCell>
                  <TableCell align='center'>AV-T3</TableCell>
                </TableRow>
              </TableHead>
              {filteredData.map((value) => {
                return (
                  <TableRow key={value.name}>
                    <TableCell>{value.name}</TableCell>
                    <TableCell align='center'>{value.TAIW ? value.TAIW : 0}</TableCell>
                    <TableCell align='center'>{value.TAI ? value.TAI : 0}</TableCell>
                    <TableCell align='center'>{value['AV-T1'] ? value['AV-T1'] : 0}</TableCell>
                    <TableCell align='center'>{value['AV-T2 (on camera)'] ? value['AV-T2 (on camera)'] : 0}</TableCell>
                    <TableCell align='center'>{value['AV-T2 (dishonesty)'] ? value['AV-T2 (dishonesty)'] : 0}</TableCell>
                    <TableCell align='center'>{value['AV-T3'] ? value['AV-T3'] : 0}</TableCell>
                  </TableRow>
                );
              })}
            </Table>
          </div>
        ) : (
          <div>
            {loadingStates.fetchAssignmentViolationReport ? (<p>Loading...</p>) : (<p>Course has no reported integrity violations.</p>)}
          </div>
        )}
      </div>
    );
  };

  const _renderStatistics = () => {
    let courseName = '';
    let instructorName = '';
    let violationType = '';
    let violationsPerCourse = {};
    let violationsPerInstructor = {};
    let violationsPerType = {};

    Object.values(courses).forEach((course) => {
      courseName = `${course.title} (${course.code})`;
      violationsPerCourse[courseName] = violations.filter((v) => v.course_id === course.id && v.rescinded === 0).length;
    });
    Object.values(instructors).forEach((instructor) => {
      instructorName = `${instructor.first_name} ${instructor.last_name}`;
      violationsPerInstructor[instructorName] = violations.filter((v) => v.instructor_name === instructorName && v.rescinded === 0).length;
    });
    Object.values(violations).forEach((violation) => {
      violationType = violation.type;
      violationsPerType[violationType] = violations.filter((v) => v.type === violation.type && v.rescinded === 0).length;
    });

    violationsPerCourse = Object.entries(violationsPerCourse).sort((a, b) => b[1] - a[1]);
    violationsPerInstructor = Object.entries(violationsPerInstructor).sort((a, b) => b[1] - a[1]);
    violationsPerType = Object.entries(violationsPerType).sort((a, b) => b[1] - a[1]);

    return (
      <Paper style={styles.statsContainer}>
        <div>
          <div>
            <div style={styles.selectHeader}>
              <h2 style={styles.simpleMargin}>View Violations by </h2>
              <Select
                style={styles.simpleMargin}
                variant='outlined'
                helperText='Course'
                value={_reportDisplay}
                onChange={(e) => setReportDisplay(e.target.value)}
              >
                <MenuItem value='Course'>Course</MenuItem>
                <MenuItem value='Instructor'>Instructor</MenuItem>
                <MenuItem value='Type'>Type</MenuItem>
              </Select>
            </div>
            {_reportDisplay === 'Course' && _renderStatsTable(violationsPerCourse, 'Course')}
            {_reportDisplay === 'Instructor' && _renderStatsTable(violationsPerInstructor, 'Instructor')}
            {_reportDisplay === 'Type' && _renderStatsTable(violationsPerType, 'Type')}
          </div>
          <hr width='100%' style={{margin: '70px 0px'}} />
          <h2 style={styles.simpleMargin}>View Violations by Assignment within a Course</h2>
          <Select
            style={styles.simpleMargin}
            variant='outlined'
            helperText='Course'
            value={_violationAssignmentCourse}
            onChange={(e) => setViolationAssignmentCourse(e.target.value)}
          >
            {Object.values(courses).filter((c) => c.disabled === 0).map((a) => (
              <MenuItem key={a.id} value={a.id}>{a.code.toUpperCase()}: {a.title}</MenuItem>
            ))}
          </Select>
        </div>
        {_violationAssignmentCourse && _renderAssignmentStatisticsTable()}
        <p style={styles.noteText}>Note: Percentages are based on the total number of academic integrity violations reported since the release of this feature (01/16/24)*</p>
      </Paper>
    );
  };

  const _renderStatsTable = (data, type) => {
    const barData = data.filter((d) => d[1] > 0).map((d) => {
      return {
        value: d[0],
        count: d[1],
      };
    });

    if (barData.length <= 0) {
      return (<CircularProgress />);
    }

    return (
      <div style={{margin: 15, flex: 1}}>
        <div style={{height: 600, width: '100%'}}>
          <ResponsiveBar
            fit={true}
            data={barData}
            keys={['count']}
            indexBy='value'
            height={600}
            width={1366}
            axisTop={null}
            axisRight={null}
            colors={{scheme: 'red_yellow_blue'}}
            colorBy='value'
            borderWidth={2}
            borderColor={{
              from: 'color',
              modifiers: [[
                'darker',
                1.6,
              ]],
            }}
            enableLabel={false}
            enableTotals={true}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 25,
              legend: type,
              legendPosition: 'middle',
              legendOffset: 100,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Amount',
              legendPosition: 'middle',
              legendOffset: -40,
            }}
            margin={{
              top: 40,
              right: 20,
              bottom: 150,
              left: 60,
            }}
            padding={0.3}
            animate={false}
            motionStiffness={90}
            motionDamping={15}
          />
        </div>
        <Table style={{border: '1px solid lightgray'}}>
          <TableHead>
            <TableRow>
              <TableCell>{type}</TableCell>
              <TableCell>Count</TableCell>
              <TableCell>Percentage</TableCell>
            </TableRow>
          </TableHead>
          {Object.values(data).map(([key, value]) => {
            if (value > 0) {
              return (
                <TableBody key={key}>
                  <TableRow>
                    <TableCell>{(type === 'Course' && Object.values(courses).find((e) => e.title === key)) ? `${Object.values(courses).find((e) => e.title === key).code.toUpperCase()}: ${key}` : key}</TableCell>
                    <TableCell>{value}</TableCell>
                    <TableCell>{`${(value / violations.filter((v) => v.rescinded === 0).length * 100).toFixed(2)}%`}</TableCell>
                  </TableRow>
                </TableBody>
              );
            }
            return null;
          })}
        </Table>
      </div>
    );
  };

  const _renderViolations = () => {
    let filteredViolations;

    const searchTerms = _searchTerm.split('|').filter((multipleSearchTerms) => multipleSearchTerms).map((searchTermPart) => searchTermPart.split(' ').map((text) => text.toLowerCase()));

    filteredViolations = violations.filter((violation) => {
      let typeMatch;
      let courseMatch;
      let instructorMatch;
      let rescinded;

      _violationType !== 'all' ? typeMatch = violation.type_id === _violationType : typeMatch = true;
      _course.length !== 0 ? courseMatch = _course.findIndex((c) => c === violation.course_id) >= 0 : courseMatch = true;
      _instructor !== 'all' ? instructorMatch = violation.instructor_name === _instructor : instructorMatch = true;
      _rescinded === false ? rescinded = !!violation.rescinded === _rescinded : rescinded = true;

      return typeMatch && courseMatch && instructorMatch && rescinded;
    });

    filteredViolations = filteredViolations.filter((violation) => {
      if (!_searchTerm) {
        return true;
      }

      return searchTerms.some((groupOfSearchTerms) => {
        return groupOfSearchTerms.every((searchTerm) => {
          return Object.keys(violation).some((violationAttribute) => {
            return `${violation[violationAttribute]}`.toLowerCase().includes(searchTerm);
          });
        });
      });
    });

    const emptyRows = _rowsPerPage - Math.min(_rowsPerPage, filteredViolations.length - _pageNum * _rowsPerPage);
    return (
      <div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Student</TableCell>
                <TableCell>Enrollment ID</TableCell>
                <TableCell>Course Code</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Instructor</TableCell>
                <TableCell>Instructor Email</TableCell>
                <TableCell>Assignment</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell>Instructor Comments</TableCell>
                <TableCell>Rescinded</TableCell>
                <TableCell>Issued</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredViolations.length > 0 && filteredViolations.slice(_pageNum * _rowsPerPage, _pageNum * _rowsPerPage + _rowsPerPage).map((violation) => {
                return (
                  <TableRow key={violation.id}>
                    <TableCell>{violation.student_name} (Id: <a href={`/students/${violation.student_id}`}>{violation.student_id}</a>)</TableCell>
                    <TableCell>{violation.enrollment_id}</TableCell>
                    <TableCell>{Object.values(courses).length > 0 && Object.values(courses).find((c) => c.id === violation.course_id).display_code_desc.toUpperCase()}</TableCell>
                    <TableCell>{violation.type}</TableCell>
                    <TableCell>{violation.instructor_name}</TableCell>
                    <TableCell>{violation.instructor_email}</TableCell>
                    <TableCell>{violation.assignment}</TableCell>
                    <TableCell>{violation.reason}</TableCell>
                    <TableCell style={{maxWidth: '250px', wordWrap: 'break-word'}}>{violation.notes ? violation.notes : 'N/A'}</TableCell>
                    <TableCell>{violation.rescinded === 0 ? 'No' : 'Yes'}</TableCell>
                    <TableCell>{dayjs(violation.created_at).format('MM-DD-YYYY')}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{height: 50 * emptyRows}}>
                  <TableCell colSpan={10} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component='div'
            count={Object.keys(filteredViolations).length}
            rowsPerPage={_rowsPerPage}
            page={_pageNum}
            previousButton={{'aria-label': 'Previous Page'}}
            nextButton={{'aria-label': 'Next Page'}}
            onPageChange={(event, _pageNum) => setPageNum(_pageNum)}
            onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
          />
        </Paper>
        <div style={styles.download}>
          <Button
            style={styles.button}
            variant='contained'
            color='primary'
            onClick={() => dispatch(fetchIntegrityViolationReport(violations, _violationType, _course, _instructor, _rescinded))}
          >
            Download Report
          </Button>
          <p><i>Please note that this will download the reports based on the selected violation type, course, instructor, and whether or not it is rescinded. This will not filter based on search terms manually entered into the search bar.</i></p>
        </div>
      </div>
    );
  };

  const _renderCoursesAndInstructors = () => {
    let validCourses = courses;
    let validInstructors = instructors;
    const filteredCourses = [];
    const filteredInstructors = [];

    if (_instructor !== 'all') {
      filteredCourses.push(instructors.find((instructor) => `${instructor.first_name} ${instructor.last_name}` === _instructor).courses_taught.map((course) => Object.values(courses).filter((c) => c.id === course.id)[0]));
      validCourses = filteredCourses[0];
    }

    if (_course.length !== 0) {
      instructors.forEach((instructor) => {
        _course.forEach((course) => {
          if (instructor.courses_taught.filter((c) => c.id === course).length > 0) {
            filteredInstructors.push(instructor);
          }
        });
      });
      validInstructors = filteredInstructors;
    }

    validCourses = Object.values(validCourses).sort((a, b) => a.code.localeCompare(b.code));
    validInstructors = Object.values(validInstructors).sort((a, b) => a.last_name.localeCompare(b.last_name));

    return (
      <>
        <FormControl style={styles.selectors}>
          <InputLabel>Course</InputLabel>
          <Select
            label='Course'
            multiple
            value={_course}
            onChange={(e) => {
              setCourse(e.target.value);
              setPageNum(0);
            }}
            renderValue={(selected) => (
              <>
                {selected.map((c) => (
                  <Chip key={c} label={Object.values(courses).find((course) => course.id === c).code} style={styles.chip}/>
                ))}
              </>
            )}
          >
            {Object.values(validCourses).map((a) => (
              <MenuItem key={a.id} value={a.id}>{a.code.toUpperCase()}: {a.title}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={styles.selectors}>
          <InputLabel>Instructor</InputLabel>
          <Select
            label='Instructor'
            value={_instructor}
            onChange={(e) => {
              setInstructor(e.target.value);
              setPageNum(0);
            }}
          >
            <MenuItem value='all'>All instructors</MenuItem>
            {Object.values(validInstructors).map((a) => (
              <MenuItem key={a.id} value={`${a.first_name} ${a.last_name}`}>{a.first_name} {a.last_name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  };

  return (
    <div>
      <h2>Integrity Violations</h2>
      <p>Here you can view integrity violations, search for specific students, violation types, courses, or instructors. You can also download a report of the violations based on the selection of violation types, courses, and instructors.</p>
      <div style={{display: 'flex'}}>
        <TextField
          style={{marginRight: 10}}
          label='Search'
          variant='outlined'
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setPageNum(0);
          }}
        />
        <FormControl style={styles.selectors}>
          <InputLabel>Violation Type</InputLabel>
          <Select
            label='Violation Type'
            value={_violationType}
            onChange={(e) => {
              setViolationType(e.target.value);
              setPageNum(0);
            }}
          >
            <MenuItem value='all'>All violations</MenuItem>
            {violationTypes.length > 0 && violationTypes.map((a) => (
              <MenuItem key={a.id} value={a.id}>{a.type}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {(courses && instructors.length > 0) && _renderCoursesAndInstructors()}
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Switch
            onChange={() => setRescinded(!_rescinded)}
          />
          <p>Show rescinded violations</p>
        </div>
      </div>
      <h2>Violations:</h2>
      {violations.length > 0 &&
        <>
          {_renderViolations()}
          {_renderStatistics()}
        </>
      }
    </div>
  );
};

export default (IntegrityViolations);
