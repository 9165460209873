/*
 * Ally Zernick
 * 2022-08-08
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  Link,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import {
  DEVELOPER,
  PARTNER_SCHOOLS,
  PARTNER_SCHOOLS_EDIT,
} from '../../Globals';
import {
  createArticulationEquivalencies,
  createInstitutionContact,
  createPartnerSchool,
  fetchCourses,
  fetchPartnerSchools,
  getAllCampuses,
  patchInstitutionContact,
  patchPartnerSchool,
  removeArticulationEquivalency,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useEffect,
  useState,
} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import DoneIcon from '@mui/icons-material/Done';
import {
  ExpandMore,
} from '@mui/icons-material';
import provinces from 'provinces';
import styles from './styles';

const PartnerSchools = () => {
  const [_searchTerm, setSearchTerm] = useState('');
  const [_schoolState, setSchoolState] = useState('state');
  const [_schoolCreationFields, setSchoolCreationFields] = useState({
    school_name: undefined,
    school_name_large: undefined,
    slug: undefined,
    img_url: undefined,
    image_to_upload: undefined,
    special_school_information: undefined,
    background_color: undefined,
    prereq_verbiage: undefined,
  });
  const [_contactCreationFields, setContactCreationFields] = useState({
    contact_name: undefined,
    email_address: undefined,
    phone: undefined,
    contact_title: undefined,
    contact_url: undefined,
    program: undefined,
  });
  const [_contactEditFields, setContactEditFields] = useState({
    contact_name: undefined,
    email_address: undefined,
    phone: undefined,
    contact_title: undefined,
    contact_url: undefined,
    program: undefined,
  });
  const [_schoolEditFields, setSchoolEditFields] = useState({
    school_name: undefined,
    school_name_large: undefined,
    slug: undefined,
    img_url: undefined,
    image_to_upload: undefined,
    special_school_information: undefined,
    background_color: undefined,
    prereq_verbiage: undefined,
  });
  const [_edit, setEdit] = useState(false);
  const [_editCardId, setEditCardId] = useState(0);
  const [_createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [_editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [_expand, setExpand] = useState(false);
  const [_expandId, setExpandId] = useState(0);
  const [_campusId, setCampusId] = useState(0);
  const [_schoolEquivalency, setSchoolEquivalency] = useState('');
  const [_equivalencies, setEquivalencies] = useState([]);
  const [_equivalency, setEquivalency] = useState();

  const admin = useSelector((state) => state.admin);
  const partnerSchools = useSelector((state) => state.partnerSchools);
  const allCampuses = useSelector((state) => state.allCampuses);
  const courses = useSelector((state) => state.courses);
  const states = provinces.filter((value) => value.country === 'US');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPartnerSchools());
    dispatch(getAllCampuses());
    dispatch(fetchCourses());
  }, [dispatch]);

  const _saveEdits = (id, contactId) => {
    if (Object.values(_schoolEditFields).filter((s) => s !== undefined).length > 0) {
      dispatch(patchPartnerSchool(id, _schoolEditFields));
    }
    if (Object.values(_contactEditFields).filter((c) => c !== undefined).length > 0) {
      dispatch(patchInstitutionContact(contactId, _contactEditFields));
    }
    _cancelEdits();
  };

  const _cancelEdits = () => {
    setEdit(!_edit);
    setSchoolEditFields({
      school_name: undefined,
      school_name_large: undefined,
      slug: undefined,
      img_url: undefined,
      image_to_upload: undefined,
      special_school_information: undefined,
      background_color: undefined,
      prereq_verbiage: undefined,
    });
    setContactEditFields({
      contact_name: undefined,
      email_address: undefined,
      phone: undefined,
      contact_title: undefined,
      contact_url: undefined,
      program: undefined,
    });
    setEquivalency();
  };

  const _renderEquivalencies = (schoolData, removeButton) => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Our Course</TableCell>
            <TableCell>Their Course</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {schoolData.equivalencies && schoolData.equivalencies.map((eq) => {
            return (
              <TableRow key={eq.id}>
                <TableCell>
                  {Object.values(courses).filter((c) => c.id === eq.course_id).map((course) => {
                    return <span key={course.id}>{course.title}</span>;
                  })}
                </TableCell>
                <TableCell style={styles.removeEquivalency}>
                  {eq.school_course_name}
                  {removeButton &&
                    <Button
                      align='right'
                      style={{marginBottom: 0}}
                      onClick={() => dispatch(removeArticulationEquivalency(eq.id), () => dispatch(fetchPartnerSchools()))}
                    >
                      <CloseIcon />
                    </Button>
                  }
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  const _renderPartners = () => {
    const filteredSchools = partnerSchools.filter((schools) => ((schools.school_name_large).toLowerCase().indexOf(_searchTerm.toLowerCase()) !== -1 || (schools.school_name).toLowerCase().indexOf(_searchTerm.toLowerCase()) !== -1) && (schools.state === _schoolState || _schoolState === 'state')).sort((a, b) => a.school_name.localeCompare(b.school_name));
    return (
      <div>
        {!Object.values(filteredSchools).length ? (_schoolState !== 'state' ? (<p>No Schools Found.</p>) : (<p>Loading</p>))
          : (<div style={styles.schoolsContainer}>
            {filteredSchools.map((schoolData) => (
              <Card key={schoolData.id}>
                <CardContent>
                  {(!!admin.perms[DEVELOPER] || !!admin.perms[PARTNER_SCHOOLS]) &&
                    <div style={styles.partnerCard}>
                      <div style={styles.schoolHeader}>
                        <h4>{schoolData.school_name}</h4>
                        {(!!admin.perms[DEVELOPER] || !!admin.perms[PARTNER_SCHOOLS_EDIT]) &&
                          <Button
                            align='right'
                            style={styles.editButton}
                            onClick={(() => {
                              setEdit(!_edit);
                              setEditCardId(schoolData.id);
                              setEditDialogIsOpen(true);
                              setCampusId(schoolData.institution_campus_id);
                            })}
                          >
                            <CreateIcon />
                          </Button>
                        }
                      </div>
                      <Link href={`https://portagelearning.com/partners/${schoolData.slug}`} target='_blank'>{schoolData.school_name_large}</Link>
                      {(schoolData.special_school_information || !!admin.perms[DEVELOPER]) && <hr width='100%' style={styles.lineSeparator} />}
                      {schoolData.special_school_information && <p>Special information: {schoolData.special_school_information}</p>}
                      <p>
                        Route url: {schoolData.slug}<br />
                        Background color: {schoolData.background_color === '#d9d9d9' ? 'Light Gray' : schoolData.background_color === '#a2a2a2' ? 'Gray' : 'Dark Gray'}<br />
                        School state: {schoolData.state}<br />
                      </p>
                      {schoolData.program && <p>Program: {schoolData.program}</p>}
                      <Accordion
                        square
                        key={schoolData.id}
                        expanded={_expand && schoolData.id === _expandId}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMore
                              sx={{pointerEvents: 'auto'}}
                            />}
                          onClick={() => {
                            setExpandId(schoolData.id);
                            setExpand(!_expand);
                          }}
                        >
                          More Information (Contacts & Equivalencies)
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            {schoolData.contact_name && <p>Contact Name: {schoolData.contact_name}</p>}
                            {schoolData.contact_email && <p>Contact Email: {schoolData.contact_email}</p>}
                            {schoolData.contact_url && <p>Contact URL: {schoolData.contact_url}</p>}
                            {schoolData.contact_title && <p>Contact Title: {schoolData.contact_title}</p>}
                            {schoolData.contact_phone && <p>Contact Phone: {schoolData.contact_phone}</p>}
                            {schoolData.contact_program && <p>Contact Program: {schoolData.contact_program}</p>}
                            <p>Equivalencies:</p>
                            {_renderEquivalencies(schoolData, false)}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <Dialog
                        open={_editDialogIsOpen && _editCardId === schoolData.id}
                        onClose={() => setEditDialogIsOpen(false)}
                      >
                        <DialogTitle>Edit partner school information:</DialogTitle>
                        <DialogContent>
                          <p>Deleting all text from an input will remove the value.</p>
                          <h2>School Information:</h2>
                          <FormControl style={styles.editForm}>
                            <TextField
                              variant='outlined'
                              label='School Name'
                              defaultValue={_schoolEditFields.school_name !== undefined ? _schoolEditFields.school_name : schoolData.school_name}
                              placeholder={schoolData.school_name}
                              onBlur={(e) => setSchoolEditFields({..._schoolEditFields, school_name: e.target.value})}
                            />
                          </FormControl>
                          <FormControl style={styles.editForm}>
                            <TextField
                              variant='outlined'
                              label='School Name Long'
                              defaultValue={_schoolEditFields.school_name_large !== undefined ? _schoolEditFields.school_name_large : schoolData.school_name_large}
                              placeholder={schoolData.school_name_large}
                              onBlur={(e) => setSchoolEditFields({..._schoolEditFields, school_name_large: e.target.value})}
                            />
                          </FormControl>
                          <FormControl style={styles.editForm}>
                            <TextField
                              variant='outlined'
                              label='Route URL'
                              defaultValue={_schoolEditFields.slug !== undefined ? _schoolEditFields.slug : schoolData.slug}
                              placeholder={schoolData.slug}
                              onBlur={(e) => setSchoolEditFields({..._schoolEditFields, slug: e.target.value})}
                            />
                          </FormControl>
                          <FormControl style={styles.editForm}>
                            <TextField
                              multiline
                              variant='outlined'
                              label='Special School Information'
                              defaultValue={_schoolEditFields.special_school_information !== undefined ? _schoolEditFields.special_school_information : schoolData.special_school_information}
                              placeholder={schoolData.special_school_information}
                              onBlur={(e) => setSchoolEditFields({..._schoolEditFields, special_school_information: e.target.value})}
                            />
                          </FormControl>
                          <Autocomplete
                            disableClearable={true}
                            style={styles.inputLabel}
                            value={schoolData.background_color}
                            id='combo-box-demo'
                            options={[...new Set(partnerSchools.map((school) => school.background_color))]}
                            onChange={(e, color) => setSchoolEditFields({..._schoolEditFields, background_color: color})}
                            renderInput={(params) =>
                              <TextField
                                {...params}
                                variant='outlined'
                                label='Background Color'
                                onChange={(e) => setSchoolEditFields(..._schoolEditFields, {background_color: e.target.value})}
                              />}
                          />
                          <FormControl style={styles.editForm}>
                            <TextField
                              variant='outlined'
                              label='Program (Not required)'
                              defaultValue={_schoolEditFields.program !== undefined ? _schoolEditFields.program : schoolData.program}
                              placeholder={schoolData.program}
                              onBlur={(e) => setSchoolEditFields({..._schoolEditFields, program: e.target.value})}
                            />
                          </FormControl>
                          <FormControl style={styles.editForm}>
                            <TextField
                              variant='outlined'
                              label='Prereq Verbiage (Not required)'
                              defaultValue={_schoolEditFields.prereq_verbiage !== undefined ? _schoolEditFields.prereq_verbiage : schoolData.prereq_verbiage}
                              placeholder={schoolData.prereq_verbiage}
                              onBlur={(e) => setSchoolEditFields({..._schoolEditFields, prereq_verbiage: e.target.value})}
                            />
                          </FormControl>
                          <Input
                            style={styles.imagePath}
                            defaultValue={`${schoolData.slug}.png`}
                            value={_schoolEditFields.img_url !== undefined ? _schoolEditFields.img_url : schoolData.img_url}
                            placeholder={'Filename'}
                            disabled={true}
                          />
                          <Button
                            variant='contained'
                            color='primary'
                            component='label'
                          >
                            Upload New Image
                            <input
                              type='file'
                              id='upload-button'
                              accept='application/png,image/*'
                              hidden={true}
                              onChange={(i) => {
                                const file = i.target.files[0];
                                if (file.size <= 1000000) {
                                  setSchoolEditFields({
                                    ..._schoolEditFields,
                                    img_url: file.name,
                                    image_to_upload: file,
                                  });
                                } else {
                                  alert('Maximum file size exceed. Please upload a file that is less than one megabyte.')
                                }
                              }}
                            />
                          </Button>
                          <h2 style={styles.topMargin}>Partner Contact Information:</h2>
                          <FormControl style={styles.editForm}>
                            <TextField
                              variant='outlined'
                              defaultValue={_contactEditFields.contact_name !== undefined ? _contactEditFields.contact_name : schoolData.contact_name}
                              onBlur={(e) => setContactEditFields({..._contactEditFields, contact_name: e.target.value})}
                              label='Contact Name (Not required)'
                            />
                          </FormControl>
                          <FormControl style={styles.editForm}>
                            <TextField
                              variant='outlined'
                              defaultValue={_contactEditFields.email_address !== undefined ? _contactEditFields.email_address : schoolData.contact_email}
                              onBlur={(e) => setContactEditFields({..._contactEditFields, email_address: e.target.value})}
                              label='Contact Email (Not required)'
                            />
                          </FormControl>
                          <FormControl style={styles.editForm}>
                            <TextField
                              variant='outlined'
                              defaultValue={_contactEditFields.contact_url !== undefined ? _contactEditFields.contact_url : schoolData.contact_url}
                              onBlur={(e) => setContactEditFields({..._contactEditFields, contact_url: e.target.value})}
                              label='Contact Url (Not required)'
                            />
                          </FormControl>
                          <FormControl style={styles.editForm}>
                            <TextField
                              variant='outlined'
                              defaultValue={_contactEditFields.contact_title !== undefined ? _contactEditFields.contact_title : schoolData.contact_title}
                              onBlur={(e) => setContactEditFields({..._contactEditFields, contact_title: e.target.value})}
                              label='Contact Title (Not required)'
                            />
                          </FormControl>
                          <FormControl style={styles.editForm}>
                            <TextField
                              variant='outlined'
                              defaultValue={_contactEditFields.phone !== undefined ? _contactEditFields.phone : schoolData.contact_phone}
                              onBlur={(e) => setContactEditFields({..._contactEditFields, phone: e.target.value})}
                              label='Contact Phone (Not required)'
                            />
                          </FormControl>
                          <FormControl style={styles.editForm}>
                            <TextField
                              variant='outlined'
                              defaultValue={_contactEditFields.program !== undefined ? _contactEditFields.program : schoolData.contact_program}
                              onBlur={(e) => setContactEditFields({..._contactEditFields, program: e.target.value})}
                              label='Contact Program (Not required)'
                            />
                          </FormControl>
                          <h2>Equivalencies:</h2>
                          {_renderEquivalencies(schoolData, true)}
                          <p>{`Please choose our course and then enter what the partner school calls it then, hit "Add Equivalency" and the equivalency will be added to the list.`}</p>
                          <div style={styles.partnerEqContainer}>
                            {!Object.values(courses).length ? (<p>Loading</p>)
                              : (
                                <Autocomplete
                                  disableClearable={true}
                                  style={styles.partnerEquivalence}
                                  id='combo-box-demo'
                                  options={Object.values(courses).filter((c) => c.disabled === 0).map((a) => ({
                                    id: a.id,
                                    code: a.code,
                                    title: a.title,
                                  }))}
                                  getOptionLabel={(course) => `${course.code}: ${course.title}`}
                                  getOptionSelected={(option, value) => option.id === value.id}
                                  onChange={(e, course) => setEquivalency(course.id)}
                                  renderInput={(params) =>
                                    <TextField
                                      {...params}
                                      label='Portage Courses'
                                    />}
                                />
                              )}
                            <TextField
                              id='partnerCourse'
                              style={styles.partnerEquivalence}
                              value={_schoolEquivalency}
                              onChange={(e) => setSchoolEquivalency(e.target.value)}
                              label='Partner Equivalency'
                              margin='dense'
                            />
                          </div>
                          <Button
                            variant='contained'
                            color='primary'
                            onClick={() => {
                              dispatch(createArticulationEquivalencies({
                                school_course_name: _schoolEquivalency,
                                course_id: _equivalency,
                                slug: schoolData.slug,
                              }));
                              setSchoolEquivalency('');
                            }}
                          >
                            Add Equivalency
                          </Button>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            align='right'
                            onClick={(() => {
                              _saveEdits(schoolData.id, schoolData.contact_id);
                              setEditDialogIsOpen(false);
                            })}
                          >
                            <DoneIcon />
                          </Button>
                          <Button
                            align='right'
                            onClick={() => {
                              _cancelEdits();
                              setEditDialogIsOpen(false);
                            }}
                          >
                            <CloseIcon />
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  }
                </CardContent>
              </Card>
            ))}
          </div>
          )
        }
      </div>
    );
  };

  return (
    <div>
      <h2>Partner Schools</h2>
      {(!!admin.perms[DEVELOPER] || !!admin.perms[PARTNER_SCHOOLS_EDIT]) &&
        <div style={styles.lineSeparator}>
          <p>If you would like to create a new Partner School use the button below.</p>
          <Button
            variant='contained'
            color='primary'
            onClick={() => setCreateDialogIsOpen(!_createDialogIsOpen)}
          >
            CREATE NEW PARTNER SCHOOL
          </Button>
          <Dialog
            open={_createDialogIsOpen}
            onClose={() => {
              setCreateDialogIsOpen(!_createDialogIsOpen);
              setSchoolCreationFields([]);
            }}
          >
            <DialogTitle>
              <div style={styles.createEquivalency}>
                Create new partner school:
                <Button
                  align='right'
                  onClick={() => {
                    setCreateDialogIsOpen(!_createDialogIsOpen);
                    setSchoolCreationFields([]);
                  }}
                >
                  <CloseIcon />
                </Button>
              </div>
            </DialogTitle>
            <DialogContent>
              <p>Required fields are marked with *.</p>
              <FormControl style={styles.editForm}>
                <TextField
                  variant='outlined'
                  onBlur={(e) => setSchoolCreationFields({..._schoolCreationFields, school_name: e.target.value})}
                  label='*School Name'
                />
              </FormControl>
              <FormControl style={styles.editForm}>
                <TextField
                  variant='outlined'
                  onBlur={(e) => setSchoolCreationFields({..._schoolCreationFields, school_name_large: e.target.value})}
                  label='*School Name Long'
                />
              </FormControl>
              <FormControl style={styles.editForm}>
                <TextField
                  variant='outlined'
                  onBlur={(e) => setSchoolCreationFields({..._schoolCreationFields, slug: e.target.value})}
                  label={`*Route URL (**this should equate to the school's URL ex. pitt.edu = pitt)`}
                />
              </FormControl>
              <FormControl style={styles.editForm}>
                <TextField
                  label='Special School Information (Not required)'
                  multiline
                  onBlur={(e) => setSchoolCreationFields({..._schoolCreationFields, special_school_information: e.target.value})}
                  minRows={4}
                  variant='outlined'
                />
              </FormControl>
              <p>This image must be a png and must be named the same as the route URL{_schoolCreationFields.slug !== undefined ? <i> ({_schoolCreationFields.slug}.png)</i> : ''}.</p>
              <TextField
                placeholder='Image Path'
                style={styles.imagePath}
                disabled={true}
                value={_schoolCreationFields.img_url}
              />
              <Button
                variant='contained'
                color='primary'
                component='label'
              >
                *Upload School Image
                <input
                  type='file'
                  id='upload-button'
                  accept='application/png,image/*'
                  hidden={true}
                  onChange={(i) => {
                    const file = i.target.files[0];
                    if (file.size <= 1000000) {
                      setSchoolCreationFields({
                        ..._schoolCreationFields,
                        img_url: file.name,
                        image_to_upload: file,
                      });
                    } else {
                      alert('Maximum file size exceed. Please upload a file that is less than one megabyte.')
                    }
                  }}
                />
              </Button>
              {!Object.values(partnerSchools).length ? (<p>Loading</p>)
                : (<>
                  <FormControl style={styles.editForm}>
                    <Autocomplete
                      disableClearable={true}
                      id='combo-box-demo'
                      options={[{label: 'Light Gray', code: '#D3D3D3'}, {label: 'Gray', code: '#808080'}, {label: 'Dark Gray', code: '#5A5A5A'}]}
                      getOptionLabel={(value) => `${value.label} (${value.code})`}
                      onChange={(e, color) => setSchoolCreationFields({..._schoolCreationFields, background_color: color.code})}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          label='*Background Color'
                          variant='outlined'
                          onChange={(e) => setSchoolCreationFields({..._schoolCreationFields, background_color: e.target.value})}
                        />}
                    />
                  </FormControl>
                  {!Object.values(allCampuses).length ? (<p>Loading</p>)
                    : (
                      <FormControl style={styles.editForm}>
                        <Autocomplete
                          disableClearable={true}
                          options={allCampuses.map((a) => ({id: a.id, name: a.name}))}
                          getOptionLabel={(activeCampus) => activeCampus.name}
                          getOptionSelected={(option, value) => option.id === value.id}
                          onChange={(e, campus) => setCampusId(campus.id)}
                          renderInput={(params) =>
                            <TextField
                              {...params}
                              variant='outlined'
                              label='*Institution Campus'
                            />}
                        />
                      </FormControl>
                    )}
                  <FormControl style={styles.editForm}>
                    <TextField
                      variant='outlined'
                      onBlur={(e) => setSchoolCreationFields({..._schoolCreationFields, program: e.target.value})}
                      label='Program (Not required)'
                    />
                  </FormControl>
                  <FormControl style={styles.editForm}>
                    <TextField
                      variant='outlined'
                      onBlur={(e) => setSchoolCreationFields({..._schoolCreationFields, prereq_verbiage: e.target.value})}
                      label='Prereq Verbiage (Not required)'
                    />
                  </FormControl>
                </>
                )}
              <h2>Contact Information:</h2>
              <FormControl style={styles.editForm}>
                <TextField
                  variant='outlined'
                  onBlur={(e) => setContactCreationFields({..._contactCreationFields, contact_name: e.target.value})}
                  label='Contact Name (Not required)'
                />
              </FormControl>
              <FormControl style={styles.editForm}>
                <TextField
                  variant='outlined'
                  onBlur={(e) => setContactCreationFields({..._contactCreationFields, email_address: e.target.value})}
                  label='Contact Email (Not required)'
                />
              </FormControl>
              <FormControl style={styles.editForm}>
                <TextField
                  variant='outlined'
                  onBlur={(e) => setContactCreationFields({..._contactCreationFields, contact_url: e.target.value})}
                  label='Contact Url (Not required)'
                />
              </FormControl>
              <FormControl style={styles.editForm}>
                <TextField
                  variant='outlined'
                  onBlur={(e) => setContactCreationFields({..._contactCreationFields, contact_title: e.target.value})}
                  label='Contact Title (Not required)'
                />
              </FormControl>
              <FormControl style={styles.editForm}>
                <TextField
                  variant='outlined'
                  onBlur={(e) => setContactCreationFields({..._contactCreationFields, phone: e.target.value})}
                  label='Contact Phone (Not required)'
                />
              </FormControl>
              <FormControl style={styles.editForm}>
                <TextField
                  variant='outlined'
                  onBlur={(e) => setContactCreationFields({..._contactCreationFields, program: e.target.value})}
                  label='Contact Program (Not required)'
                />
              </FormControl>
              <h2>Course Equivalencies:</h2>
              <p>{`Please choose our course and then enter what the partner school calls it then, hit "Add Equivalency" and the equivalency will be added to the list.`}</p>
              <div style={styles.partnerEqContainer}>
                {!Object.values(courses).length ? (<p>Loading</p>)
                  : (
                    <Autocomplete
                      disableClearable={true}
                      style={styles.partnerEquivalence}
                      options={Object.values(courses).filter((c) => c.disabled === 0).map((a) => ({
                        id: a.id,
                        code: a.code,
                        title: a.title,
                      }))}
                      getOptionLabel={(course) => `${course.code}: ${course.title}`}
                      getOptionSelected={(option, value) => option.id === value.id}
                      onChange={(e, course) => setEquivalency(course.id)}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          label='Portage Courses'
                        />}
                    />
                  )}
                <TextField
                  id='partnerCourse'
                  style={styles.partnerEquivalence}
                  value={_schoolEquivalency}
                  onChange={(e) => setSchoolEquivalency(e.target.value)}
                  label='Partner Equivalency'
                  margin='dense'
                />
              </div>
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  setEquivalencies([..._equivalencies, {course_id: _equivalency, school_course_name: _schoolEquivalency}]);
                  setSchoolEquivalency('');
                }}
              >
                Add Equivalency
              </Button>
              <p>Equivalencies being added:</p>
              {_equivalencies.length === 0 && <p><i>There have been no equivalencies added.</i></p>}
              {_equivalencies.length > 0 && _equivalencies.map((eq) => {
                return (
                  <span key={eq.id} style={styles.createEquivalency}>
                    <div>
                      Our course: {Object.values(courses).filter((c) => c.id === eq.course_id).map((course) => {
                        return <span key={course.id}>{course.title}</span>;
                      })}<br />
                      Their course: {eq.school_course_name}<br /><br />
                    </div>
                    <Button
                      align='right'
                      onClick={() => {
                        _equivalencies.pop(eq);
                        setEquivalency('');
                      }}
                    >
                      <CloseIcon />
                    </Button>
                  </span>
                );
              })}
            </DialogContent>
            <DialogActions style={{justifyContent: 'flex-begin'}}>
              <Button
                variant='contained'
                color='primary'
                disabled={(_campusId === 0 || !_schoolCreationFields.slug || !_schoolCreationFields.school_name || !_schoolCreationFields.school_name_large || !_schoolCreationFields.background_color || !_schoolCreationFields.img_url || !_schoolCreationFields.image_to_upload)}
                onClick={() => {
                  dispatch(createPartnerSchool(_campusId, _schoolCreationFields,
                    () => {
                      _equivalencies.map((eq) => {
                        dispatch(createArticulationEquivalencies({
                          school_course_name: eq.school_course_name,
                          course_id: eq.course_id,
                          slug: _schoolCreationFields.slug,
                        }));
                      });
                    }));
                  if (Object.values(_contactCreationFields).filter((c) => c !== undefined).length > 0) {
                    dispatch(createInstitutionContact(_campusId, _contactCreationFields));
                  }
                  setCreateDialogIsOpen(!_createDialogIsOpen);
                }}
              >
                Create Partner School
              </Button>
              <p style={{color: 'gray'}}>All fields marked with * must be filled out.</p>
            </DialogActions>
          </Dialog>
        </div>
      }
      <div style={styles.schoolHeader}>
        <h3>Search partners:</h3>
        <TextField
          id='schoolSearch'
          style={styles.searchBox}
          variant='outlined'
          value={_searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          label='Search'
          margin='dense'
        />
        <Select
          onChange={(e) => setSchoolState(e.target.value)}
          value={_schoolState}
          style={styles.stateSelect}
        >
          <MenuItem value='state'>All States</MenuItem>
          {states.map((s) => {
            return (
              <MenuItem key={s.short} value={s.short}>{s.name}</MenuItem>
            );
          })}
        </Select>
      </div>
      {partnerSchools.length > 0 && _renderPartners()}
    </div>
  );
};

export default (PartnerSchools);
