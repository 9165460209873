/*
 * Nathaniel Padgett
 * 2021-02-10
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import {
  DEVELOPER,
} from '../../../Globals';
import React from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  manuallyEnrollStudent,
} from '../../../actions';
import styles from './styles';

const SELECT_INITIAL_VALUE = 0;

const ManualEnrollment = ({onClose, studentId}) => {
  const admin = useSelector((state) => state.admin);
  const courses = useSelector((state) => state.courses);
  const [unpaidNoteError, setUnpaidNoteError] = React.useState(true);
  const [courseAsUnpaid, setCourseAsUnpaid] = React.useState(false);
  const [addCourseSelectionId, setAddCourseSelectionId] = React.useState(0);
  const [displayUnpaidNotes, setDisplayUnpaidNotes] = React.useState(false);
  const [suppressWelcomeEmail, setSuppressWelcomeEmail] = React.useState(false);
  const [unpaidNoteValue, setUnPaidNoteValue] = React.useState('');
  const [assignInstructorManually, setAssignInstructorManually] = React.useState(false);
  const dispatch = useDispatch();

  return (
    <div>
      <div style={styles.addCourseWrapper}>
        <Select
          style={styles.addCourseSelectField}
          value={addCourseSelectionId}
          onChange={(e) => setAddCourseSelectionId(e.target.value)}
        >
          {[
            <MenuItem key='placeholder' value={SELECT_INITIAL_VALUE} disabled={true}>Course*</MenuItem>,
            Object.values(courses).filter((course) => course.disabled === 0).map((course) => <MenuItem key={course.id} value={course.id}>{`${course.code}: ${course.title}`}</MenuItem>),
          ]}
        </Select>
        &nbsp;&nbsp;
        <Button
          onClick={() => {
            if (!courseAsUnpaid || !unpaidNoteError) {
              dispatch(manuallyEnrollStudent(studentId, addCourseSelectionId, suppressWelcomeEmail, courseAsUnpaid, unpaidNoteValue, assignInstructorManually));
              onClose();
            }
          }}
          disabled={!addCourseSelectionId}
          variant='contained'
          color='secondary'
        >
          Enroll
        </Button>
        <Button
          onClick={() => {
            onClose();
          }}
          color='primary'
        >
          Cancel
        </Button>
      </div>
      <div>
        {!!admin.perms[DEVELOPER] &&
          <FormControlLabel
            control={
              <Checkbox
                checked={suppressWelcomeEmail}
                onChange={() => {
                  setSuppressWelcomeEmail(!suppressWelcomeEmail);
                }}
                name='suppressWelcomeEmail'
                color='primary'
              />
            }
            label='Suppress welcome email to student'
          />
        }
        <FormControlLabel
          control={
            <Checkbox
              checked={assignInstructorManually}
              onChange={() => {
                setAssignInstructorManually(!assignInstructorManually);
              }}
              name='assignInstructorManually'
              color='primary'
            />
          }
          label="Don't automatically assign instructor"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={courseAsUnpaid}
              onChange={(event) => {
                setCourseAsUnpaid(event.target.checked);
                setDisplayUnpaidNotes(event.target.checked);
              }}
              name='setCourseAsNotPaid'
              color='primary'
            />
          }
          label='Set course as not paid'
        />
        {displayUnpaidNotes &&
          <TextField
            style={styles.textField}
            label='Unpaid Note'
            error={unpaidNoteError}
            placeholder='Add note to course enrollment'
            value={unpaidNoteValue}
            onChange={(e) => {
              setUnPaidNoteValue(e.target.value);
              setUnpaidNoteError(e.target.value.length === 0);
            }}
            margin='dense'
            required
          />
        }
      </div>

    </div>
  );
};

export default (ManualEnrollment);
