/*
 * Nathaniel Padgett
 * 2021-07-21
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import React from 'react';
import {
  TableCell,
} from '@mui/material';
import {
  formatNumberAsUScurrency,
} from '../../../utilities';

const EditableTableCell = ({value, formatAsCurrency, updateValue, readOnly}) => {
  const [_currentValue, setCurrentValue] = React.useState(value);
  const [_editableMode, setEditableMode] = React.useState(false);
  const textInput = React.createRef();

  const updateValueIfChanged = () => {
    setEditableMode(false);
    if (value !== _currentValue.trim()) {
      updateValue(_currentValue);
    }
  };

  React.useEffect(() => {
    textInput.current?.focus();
  });

  if (!readOnly) {
    if (!_editableMode) {
      return (
        <TableCell
          onDoubleClick={() => {
            setEditableMode(true);
          }}
        >{formatAsCurrency ? formatNumberAsUScurrency(value) : value}</TableCell>
      );
    }
    return (
      <TableCell
        onKeyUp={(e) => {
          if (['Escape', 'Enter'].includes(e.key)) {
            updateValueIfChanged();
          }
        }}
      >
        <input
          type='text'
          value={_currentValue}
          ref={textInput}
          onChange={(e) => {
            setCurrentValue(e.target.value);
          }}
          onBlur={updateValueIfChanged}
        ></input>
      </TableCell>
    );
  } else {
    return (
      <TableCell>{formatAsCurrency ? formatNumberAsUScurrency(value) : value}</TableCell>
    );
  }
};

export default EditableTableCell;
