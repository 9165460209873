/*
 * Nick Lewyn & Andrew Moskal
 * 2024-01-23
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {
  fetchResetUserPassword,
} from '../../../actions';
import {
  useDispatch,
} from 'react-redux';
import {
  useState,
} from 'react';

const ResetUserPassword = ({firstName, lastName, schoolName, userId, requestType}) => {
  const [_buttonResetPasswordDisabled, setButtonResetPasswordDisabled] = useState(false);
  const [_openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();

  return (
    <div>
      <Button
        variant='contained'
        color='primary'
        disabled={_buttonResetPasswordDisabled}
        onClick={() => setOpenDialog(true)}
      >
        Reset Password
      </Button>
      <Dialog
        open={_openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Reset Password</DialogTitle>
        <DialogContent>
          <div>
            {(firstName && lastName &&
            <p>An email will be sent to {firstName + ' ' + lastName} with their temporary password.</p>) ||
              (schoolName &&
            <p>An email will be sent to {schoolName} with the temporary password</p>) ||
            <p>An email will be sent to the user with their temporary password.</p>}
            <p>{"Are you sure you want to reset this user's password?"}</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              dispatch(fetchResetUserPassword(userId, requestType));
              setButtonResetPasswordDisabled(true);
              setOpenDialog(false);
            }}
          >
            Yes
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              setOpenDialog(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ResetUserPassword;
