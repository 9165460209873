/*
 * Ally Zernick
 * 05-17-2023
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */

const styles = {
  schoolsContainer: {
    marginTop: '25px',
    display: 'grid',
    grid: 'auto-flow / 1fr 1fr 1fr',
    gap: '25px',
  },
  schoolHeader: {
    display: 'flex',
    lineHeight: '1.25',
  },
  partnerCard: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '1.5',
  },
  lineSeparator: {
    margin: '25px 0px 10px',
  },
  editForm: {
    margin: '10px 0px',
    width: '100%',
  },
  inputLabel: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  searchBox: {
    margin: '10px',
  },
  stateSelect: {
    height: '40px',
    minWidth: '200px',
    marginTop: '10px',
  },
  partnerEquivalence: {
    width: '48%',
  },
  partnerEqContainer: {
    width: '95%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 10,
  },
  moreInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  imagePath: {
    marginRight: '10px',
  },
  removeEquivalency: {
    display: 'flex',
    alignItems: 'center',
  },
  createEquivalency: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  topMargin: {
    marginTop: '25px',
  },
  editFields: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
};

export default styles;
