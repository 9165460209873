/*
 * Nick Lewyn
 * 2020-10-30
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */

import {
  Box,
  CircularProgress,
  Grid2 as Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import {
  clearTranscriptHistory,
  fetchTranscriptRequestHistory,
} from '../../../actions';
import React from 'react';
import {
  TRANSCRIPT_REQUEST_STATUSES,
} from '../../../Globals';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import styles from './styles';

const TRANSCRIPT_REQUEST_STATUS = TRANSCRIPT_REQUEST_STATUSES;

class TranscriptHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transcriptHistoryRequestIsEmpty: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.transcriptRequestHistory.request_history !== this.props.transcriptRequestHistory.request_history) {
      if (this.props.transcriptRequestHistory.request_history.length === 0) {
        this.setState({transcriptHistoryRequestIsEmpty: true});
      }
    }
  }

  componentDidMount() {
    this.props.fetchTranscriptRequestHistory(this.props.studentId);
  }

  componentWillUnmount() {
    this.setState({transcriptHistoryRequestIsEmpty: false});
    this.props.clearTranscriptHistory();
  }

  _renderStatus(request) {
    const transcriptStatus = Object.values(TRANSCRIPT_REQUEST_STATUS).filter((val) => val === request.request_status)[0];
    const status = <span style={styles.titleFormat}>Status: </span>;
    if (transcriptStatus === TRANSCRIPT_REQUEST_STATUS.Completed) {
      return (<p>{status} Completed {new Date(request.date_processed).toLocaleDateString()}</p>
      );
    } else if (transcriptStatus === TRANSCRIPT_REQUEST_STATUS.Requested) {
      return (<p>{status} Requested</p>);
    } else {
      return (<p>{status} <span style={styles.invalidRequest}>Invalid Request</span></p>);
    }
  }

  render() {
    if (!this.state.transcriptHistoryRequestIsEmpty && this.props.transcriptRequestHistory.request_history.length === 0) {
      return <div><CircularProgress color='secondary' /></div>;
    }

    if (this.state.transcriptHistoryRequestIsEmpty) {
      return 'There are no transcript request records for this student.';
    }

    return (
      <Paper>
        <Table>
          <TableBody>
            {!this.transcriptHistoryRequestIsEmpty && this.props.transcriptRequestHistory.request_history.map((requests) => {
              return (
                <React.Fragment key={requests.transcript_request_id}>
                  <TableRow>
                    <TableCell>
                      <Grid container style={styles.headerDetails}>
                        <Grid item><span style={styles.titleFormat}>Requested: </span>{new Date(requests.request_created).toLocaleDateString()}</Grid>
                        <Grid item><span style={styles.titleFormat}>Type: </span> {requests.request_type_description}</Grid>
                        <Grid item>{this._renderStatus(requests)}</Grid>
                      </Grid>
                      <div>
                        <Grid container spacing={1}>
                          <Grid item align='right'><span style={styles.titleFormat}>Send To:</span></Grid>
                          <Grid item>
                            <Box>{requests.send_to_name}</Box>
                            <Box>{requests.send_to_contact}</Box>
                            <Box>{requests.send_to_address}</Box>
                            <Box>{requests.send_to_address2}</Box>
                            <Box>{`${requests.send_to_city}, ${requests.send_to_state} ${requests.send_to_zip}`}</Box>
                          </Grid>
                          <Grid item><span style={styles.titleFormat}>Notes: </span>{requests.notes}</Grid>
                        </Grid>
                      </div>
                      <div style={styles.bodyDetails}>
                        <Grid container alignItems='baseline' spacing={1}>
                          <Grid item align='right'><span style={styles.titleFormat}>Courses:</span></Grid>
                          <Grid item>{requests.courses.map((courses) => {
                            return (
                              <React.Fragment key={courses.id}>
                                <Box>{courses.course}</Box>
                              </React.Fragment>
                            );
                          })
                          }
                          </Grid>
                        </Grid>
                      </div>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    transcriptRequestHistory: state.transcriptRequestHistory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    clearTranscriptHistory,
    fetchTranscriptRequestHistory,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TranscriptHistory);
