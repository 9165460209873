/*
 * Rob DePietro
 * 2024-09-19
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  updateEnrollments,
} from '../../../../actions';

const UndoChangeInRegistrationDialog = ({
  studentCourse, setUndoRegistrationDialog, student, isUndoRegistrationOpen,
}) => {
  const studentCourses = useSelector((state) => state.studentCourses);
  const previousEnrollment = studentCourses[student.id].find((enrollment) => enrollment.id === studentCourse.change_of_previous_enrollment_id);
  const dispatch = useDispatch();

  const _undoChangeInRegistrationDialogCLose = () => {
    setUndoRegistrationDialog(false);
  };

  return (
    <Dialog
      open={isUndoRegistrationOpen}
      onClose={() => _undoChangeInRegistrationDialogCLose()}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Are you sure you want to undo this change in registration?</DialogTitle>
      <DialogContent>
        {previousEnrollment.refunds.length > 1 &&
        <span style={{lineHeight: '1.2'}}>{`Our records indicate that this student has multiple open refunds for the course you are trying to go back to, which means you will need to verify manually which refund needs to be rejected for undoing a change in registration after confirming`}.</span>
        }
        {previousEnrollment.transactions_enrollments.transactions.find((enrollment) => enrollment.payment_type === 'refund' && enrollment.status === 'complete') &&
        <span style={{lineHeight: '1.2'}}>{`Our records indicate that this student has already had a completed refund for the enrollment you are going back to. Please verify that this student hasn't already received a refund for the course you are going back to`}.</span>

        }
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            dispatch(updateEnrollments(
              studentCourse.id,
              {
                undo_change_in_registration: 1,
              },
            )); _undoChangeInRegistrationDialogCLose();
          }}
          variant='contained'
          color='primary'
        >
          Confirm
        </Button>
        <Button
          color='secondary'
          onClick={() => _undoChangeInRegistrationDialogCLose()}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UndoChangeInRegistrationDialog;
