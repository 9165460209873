/*
 * Ryan O'Dowd
 * 2019-01-22
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Paper,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
} from '@mui/material';
import Globals, {
  DEVELOPER,
  INSTRUCTOR_ASSIGNMENT,
} from '../../Globals';
import {
  clearNABCCanvasCoursePoints,
  closeForceConcludeDialog,
  fetchCognitoDuplicateStudent,
  fetchCourses,
  fetchPreviousInfoForStudent,
  fetchStudent,
  fetchStudents,
  fetchUnassignedStudentIds,
  removeCognitoStudentData,
} from '../../actions';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import Details from './Details';
import EyeOffIcon from 'mdi-react/EyeOffIcon';
import FlagIcon from 'mdi-react/FlagIcon';
import Highlighter from 'react-highlight-words';
import Radium from 'radium';
import React from 'react';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import dayjs from 'dayjs';
import {
  getFormattedPhone,
} from '../../utilities';
import styles from './styles';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

class StudentManagement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNum: 0,
      rowsPerPage: 5,
      selectedStudentId: null,

      errorNoNotesText: false,
      errorNoChangeOnUpdate: false,

      displayActiveStudentsOnly: true,
      displayUnassignedStudentsOnly: false,

      // controlled inputs
      searchText: '',

      assignInstructorManually: false,
      displayDuplicateDialog: false,
      duplicateStudentEmail: '',
      disableDeleteDuplicateAccount: true,
    };
  }

  componentDidMount() {
    this.props.fetchUnassignedStudentIds();
    if (window.location.pathname.substring(10) !== '') {
      this.props.fetchStudent(window.location.pathname.substring(10));
    }
    this.props.fetchStudents(true, '', () => {
      if (window.location.pathname.substring(10) !== '') {
        this.props.fetchStudent(window.location.pathname.substring(10));
        this.props.fetchPreviousInfoForStudent(window.location.pathname.substring(10));
      }
    });

    this.props.fetchCourses();
    this.setState({selectedStudentId: window.location.pathname.substring(10)});
    if (window.location.search && this.state.selectedStudentId !== '') {
      this.setState({searchText: new URL(window.location.href).search.substring(1)});
    }
  }

  _setEnableDeleteDuplicateButton(cognitoObject) {
    if (cognitoObject.Attributes?.length === 1) {
      this.setState({disableDeleteDuplicateAccount: false});
    }
  }

  _renderDuplicateEmailDialog() {
    return (
      <Dialog
        open={true}
        onClose={() => this.setState({displayDuplicateDialog: false, duplicateStudentEmail: ''})}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='md'
      >
        <DialogTitle id='alert-dialog-title'>Check Duplicate Canvas Account</DialogTitle>
        <DialogContent>
          <div>
            <p>Enter a student email to check for a duplicate account</p>
            <div style={styles.duplicateEmailDetails}>
              <TextField
                style={styles.duplicateTextSearch}
                label='Student Email'
                value={this.state.duplicateStudentEmail}
                onChange={(e) => this.setState({duplicateStudentEmail: e.target.value})}
                margin='dense'
              />
              <Button
                style={styles.duplicateEmailDialogSearchButton}
                variant='contained'
                color='primary'
                onClick={() => this.props.fetchCognitoDuplicateStudent(this.state.duplicateStudentEmail, (cognitoObject) => this._setEnableDeleteDuplicateButton(cognitoObject))}
                disabled={!this.state.duplicateStudentEmail}
              >
                Search
              </Button>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            disabled={this.state.disableDeleteDuplicateAccount}
            onClick={() => {
              this.props.removeCognitoStudentData(this.state.duplicateStudentEmail);
              this.setState({disableDeleteDuplicateAccount: true});
            }}
          >
            Delete duplicate account
          </Button>
          <Button
            color='secondary'
            onClick={() => this.setState({
              displayDuplicateDialog: false,
              duplicateStudentEmail: '',
              disableDeleteDuplicateAccount: true,
            })}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const searchTerms = this.state.searchText.split('|').filter((multipleSearchTerms) => multipleSearchTerms).map((searchTermPart) => searchTermPart.split(' ').map((text) => text.toLowerCase()));

    const wordsToMatch = () => {
      let searchWords = [];
      this.state.searchText.split(' ').forEach((term) => {
        if (term.includes('|')) {
          searchWords = [...searchWords, ...term.split('|').filter((value) => value)];
        } else {
          searchWords = [...searchWords, term];
        }
      });
      return searchWords;
    };

    let filteredStudents = this.props.students.filter((student) => {
      if (!this.state.searchText) {
        return true;
      }

      return searchTerms.some((groupOfSearchTerms) => {
        return groupOfSearchTerms.every((searchTerm) => {
          return Object.keys(student).some((studentAttribute) => {
            if (studentAttribute === 'phone') {
              return `${student[studentAttribute]}`.replace(/\D/g, '').includes(searchTerm);
            }
            return `${student[studentAttribute]}`.toLowerCase().includes(searchTerm);
          });
        });
      });
    });
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, filteredStudents.length - this.state.pageNum * this.state.rowsPerPage);

    if (this.state.displayUnassignedStudentsOnly) {
      filteredStudents = filteredStudents.filter((student) => {
        // return unassigned students and any student who registered in the last 24 hours
        return this.props.unassignedStudentIds.map((o) => o.id).includes(student.id) || dayjs(student.date_of_most_recent_registration).unix() > (dayjs().utc().subtract({hours: 24}).unix());
      }).sort((student1, student2) => {
        if (dayjs(student1.date_of_most_recent_registration).unix() > dayjs(student2.date_of_most_recent_registration).unix()) {
          return -1;
        }
        if (dayjs(student1.date_of_most_recent_registration).unix() < dayjs(student2.date_of_most_recent_registration).unix()) {
          return 1;
        }
        return 0;
      });
    }

    return (
      <div style={styles.container}>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          autoHideDuration={5000}
          open={this.state.errorNoChangeOnUpdate}
          onClose={() => this.setState({errorNoChangeOnUpdate: false})}
        >
          <Alert severity={'error'} variant='standard'>
            <div style={styles.alertContainer}>
              <p id='message-id'>Error: No changes made on update request</p>
              <IconButton
                key='close'
                onClick={() => this.setState({errorNoChangeOnUpdate: false})}
                size='large'
              >
                <CloseIcon />
              </IconButton>
            </div>
          </Alert>
        </Snackbar>
        {this.state.displayDuplicateDialog && this._renderDuplicateEmailDialog()}
        <h3>Students</h3>
        <Paper>
          <Toolbar style={styles.tableToolbar}>
            <div style={styles.leftItems}>
              <TextField
                style={styles.textField}
                label='Search'
                value={this.state.searchText}
                onChange={(e) => this.setState({searchText: e.target.value})}
                margin='dense'
              />
              {this.props.admin.department !== Globals.userDepartments.facultyDepartmentChair &&
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.displayActiveStudentsOnly}
                      onChange={(event) => {
                        if (!event.target.checked && !this.state.allStudentsFetched) {
                          this.props.fetchStudents(false);
                          this.setState({allStudentsFetched: true});
                        }
                        this.setState({displayActiveStudentsOnly: event.target.checked});
                      }}
                    />
                  }
                  label='Active only'
                />
              }
              {!!this.props.admin.perms[INSTRUCTOR_ASSIGNMENT] &&
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.displayUnassignedStudentsOnly}
                      onChange={(event) => {
                        if (event.target.checked) {
                          this.props.fetchUnassignedStudentIds();
                        }
                        this.setState({displayUnassignedStudentsOnly: event.target.checked});
                      }}
                    />
                  }
                  label='Unassigned only'
                />
              }
            </div>
            <div>
              <Button
                variant='contained'
                color='primary'
                onClick={() => this.setState({displayDuplicateDialog: true})}
              >
                Check duplicate account
              </Button>
            </div>
          </Toolbar>
          <div>
            <Table
              aria-labelledby='tableTitle'
              size='small'
            >
              <TableHead>
                <TableRow>
                  {!!this.props.admin.perms[DEVELOPER] &&
                    <>
                      <TableCell align='right'>NABC ID</TableCell>
                      <TableCell align='right'>Canvas ID</TableCell>
                    </>
                  }
                  {this.state.displayUnassignedStudentsOnly && <TableCell align='right'>Instructor</TableCell>}
                  <TableCell align='right'>Name</TableCell>
                  <TableCell align='right'>Email</TableCell>
                  <TableCell align='right'>Phone Number</TableCell>
                  <TableCell align='right'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredStudents.slice(this.state.pageNum * this.state.rowsPerPage, this.state.pageNum * this.state.rowsPerPage + this.state.rowsPerPage).map((student) => {
                  return (
                    <React.Fragment key={student.id}>
                      <TableRow
                        style={+this.state.selectedStudentId === +student.id ? styles.selectedStudentRow : styles.studentRow}
                        hover
                        tabIndex={-1}
                        onClick={() => {
                          this.props.fetchStudent(student.id);
                          this.props.fetchPreviousInfoForStudent(student.id);
                          this.setState({selectedStudentId: student.id});
                          window.history.pushState({prevId: student.id}, null, `/students/${student.id}`);
                        }}
                      >
                        {!!this.props.admin.perms[DEVELOPER] &&
                          <>
                            <TableCell align='right'>
                              <Highlighter
                                highlightStyle={styles.highlightedText}
                                searchWords={wordsToMatch()}
                                autoEscape={true}
                                textToHighlight={`${student.id}`}
                              />
                            </TableCell>
                            <TableCell align='right'>
                              <Highlighter
                                highlightStyle={styles.highlightedText}
                                searchWords={wordsToMatch()}
                                autoEscape={true}
                                textToHighlight={`${student.canvas_id}`}
                              />
                            </TableCell>
                          </>
                        }
                        {this.state.displayUnassignedStudentsOnly &&
                          <TableCell align='right'>
                            {this.props.unassignedStudentIds.map((o) => o.id).includes(student.id) && <FlagIcon color={this.props.unassignedStudentIds.find((o) => o.id === student.id).has_more_than_two_attempts ? '#f00' : '#000'}/>}
                          </TableCell>
                        }
                        <TableCell align='right'>
                          <Highlighter
                            highlightStyle={styles.highlightedText}
                            searchWords={wordsToMatch()}
                            autoEscape={true}
                            textToHighlight={`${student.first_name} ${student.last_name}`}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <Highlighter
                            highlightStyle={styles.highlightedText}
                            searchWords={wordsToMatch()}
                            autoEscape={true}
                            textToHighlight={student.email}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          {getFormattedPhone(student.phone)}
                          {/* @TODO: index error on highlighted text:
                          <Highlighter
                            highlightStyle={styles.highlightedText}
                            searchWords={this.state.searchText.split(' ')}
                            autoEscape={true}
                            textToHighlight={this._formatPhone(student.phone)}
                            sanitize={t => t.replace(/\D/g, '')}
                          />
                          */}
                        </TableCell>
                        <TableCell align='right'>
                          {/* @TODO: add tooltip to EyeOffIcon to explain what it is */}
                          {!!student.disabled && <EyeOffIcon />}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{height: 49 * emptyRows}}>{/* @TODO: magic number */}
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          {/* @TODO: update pagination props */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component='div'
            count={filteredStudents.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.pageNum}
            previousButton={{'aria-label': 'Previous Page'}}
            nextButton={{'aria-label': 'Next Page'}}
            onPageChange={(event, pageNum) => this.setState({pageNum})}
            onRowsPerPageChange={(event) => this.setState({rowsPerPage: event.target.value})}
          />
        </Paper>
        <h3>Student details</h3>
        {/* @TODO: passing in studentCourses isn't causing a rerender on instructor assignment */}
        {this.state.selectedStudentId &&
          <Details
            studentId={+this.state.selectedStudentId}
            studentCourses={this.props.studentCourses}
          />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    departmentChairCourses: state.departmentChairCourses,
    students: Object.values(state.students).sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)),
    unassignedStudentIds: state.unassignedStudentIds,
    admin: state.admin,
    studentCognitoInfo: state.studentCognitoInfo,
    studentCourses: state.studentCourses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    clearNABCCanvasCoursePoints,
    closeForceConcludeDialog,
    fetchCognitoDuplicateStudent,
    fetchCourses,
    fetchPreviousInfoForStudent,
    fetchUnassignedStudentIds,
    fetchStudent,
    fetchStudents,
    removeCognitoStudentData,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Radium(StudentManagement));
